import '../sass/app.scss'

import 'what-input'
import { createFocusTrap } from 'focus-trap'
import 'waypoints/lib/noframework.waypoints.min.js'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import.meta.glob('../images/**/*', { as: 'url', eager: true })

const dataModules = [...document.querySelectorAll('[data-module]')]

class Toggle {
  constructor(el, callback = null) {
    this.button = el
    this.toggleCallback = callback
    this.expanded = false
    this.hasPressedAttr = this.button.hasAttribute('aria-pressed')

    const targetId = this.button.getAttribute('aria-controls')
    this.target = document.getElementById(targetId)
    this.targetClass = this.button.dataset.toggleTargetClass

    this.bindEvents()
  }

  bindEvents() {
    this.button.addEventListener('click', this.toggle.bind(this))
  }

  toggle() {
    this.expanded = !this.expanded
    this.target.classList.toggle(this.targetClass)
    this.button.setAttribute('aria-expanded', this.expanded)

    if (this.hasPressedAttr) {
      this.button.setAttribute('aria-pressed', this.expanded)
    }

    if (this.toggleCallback) {
      this.toggleCallback()
    }
  }
}

class Menu {
  constructor(el) {
    this.button = el
    this.bodyEl = document.body
    this.text = el.querySelector('[data-menu-text]')
    this.toggle = new Toggle(this.button, () => {
      this.toggle.expanded ? this.freeze() : this.unfreeze()
    })
    this.scrollDist = null
    this.escapeHandler = this.handleEscapePress.bind(this)

    this.header = document.getElementById('header')
    this.headerNav = document.getElementById('header-nav')
    this.focusTrap = createFocusTrap(this.header, {
      escapeDeactivates: true,
    })
  }

  freeze() {
    // get scroll distance before freeze
    this.scrollDist = this.bodyEl.scrollTop
    this.text.innerText = 'Close Menu'

    // setTimeout(() => {
      this.focusTrap.activate()
    // }, 50)

    disableBodyScroll(this.headerNav)

    this.text.classList.add('sr-only')

    window.addEventListener('keydown', this.escapeHandler)
  }


  unfreeze() {
    this.bodyEl.scrollTop = this.scrollDist
    this.text.innerText = 'Menu'

    this.focusTrap.deactivate()

    enableBodyScroll(this.headerNav)

    this.text.classList.remove('sr-only')

    window.removeEventListener('keydown', this.escapeHandler)
  }

  handleEscapePress(e) {
    if (e.keyCode === 27) {
      this.toggle.toggle()

      this.button.focus()
    }
  }
}

class Video {
  constructor(el) {
    this.button = el
    this.video = document.getElementById(
      this.button.getAttribute('aria-controls'),
    )
    this.source = this.video.querySelector('source').getAttribute('src')

    this.bindEvents()
  }

  bindEvents() {
    this.button.addEventListener('click', this.playVideo.bind(this))
    this.video.addEventListener('ended', this.endVideo.bind(this))
  }

  playVideo() {
    this.button.classList.add('playing')

    window.setTimeout(
      function () {
        this.video.play()
        this.video.setAttribute('controls', '')
        this.video.setAttribute('data-track-gtm', `Video|Play|${this.source}`)
      }.bind(this),
      1000,
    )
  }

  endVideo() {
    this.video.load()
    this.video.removeAttribute('controls')

    window.setTimeout(
      function () {
        this.button.classList.remove('playing')
      }.bind(this),
      1000,
    )
  }
}

export default class VideoEmbed {
  constructor(el) {
    this.el = el
    this.setVars()
    this.bindEvents()
  }

  setVars() {
    this.button = this.el.querySelector('[data-video-poster]')
    this.iframe = this.el.querySelector('iframe')
  }

  bindEvents() {
    this.button.addEventListener('click', this.revealVideo.bind(this))
  }

  revealVideo() {
    this.iframe.src = this.iframe.dataset.src
    this.iframe.setAttribute(
      'data-track-gtm',
      `Video|Play|${this.iframe.dataset.src}`,
    )
    this.iframe.focus()
    this.button.classList.add('-active')
  }
}

class Factoids {
  constructor(el) {
    this.el = el
    this.mql = window.matchMedia('(min-width: 480px)')
    this.factoids = el.querySelectorAll('[data-factoid]')
    this.setup()
  }

  setup() {
    if (!this.mql.matches) {
      this.closeFactoids()
    }
    this.mql.onchange = (e) => {
      if (e.matches) {
        this.openFactoids()
      } else {
        this.closeFactoids()
      }
    }
  }

  openFactoids = () => {
    this.factoids.forEach((factoid) => {
      factoid.setAttribute('open', '')
    })
  }

  closeFactoids = () => {
    this.factoids.forEach((factoid) => {
      factoid.removeAttribute('open')
    })
    this.factoids[0].setAttribute('open', '')
  }
}

class ScrollInView {
  constructor(el) {
    this.blocks = el.querySelectorAll('[data-block]')

    this.bindEvents()
  }

  bindEvents() {
    this.blocks.forEach((block) => {
      const blockMarker = block.querySelector('[data-block-marker]')
      if (!blockMarker) {
        return
      }
      new Waypoint({
        element: blockMarker,
        handler: function () {
          block.classList.add('-scrolled-in-view')
          this.destroy()
        },
        offset: '75%',
      })
    })
  }
}

class VideoControl {
  constructor(el) {
    this.button = el
    this.player = document.getElementById(this.button.dataset.playerId)
    this.bindEvents()

    if (this.player) {
      this.autoplay()
    }
  }

  bindEvents() {
    this.button.addEventListener('click', this.playPause.bind(this))
  }

  autoplay() {
    var saveDataEnabled = false

    if ('connection' in navigator) {
      saveDataEnabled = navigator.connection.saveData === true
    }

    const prefersReducedMotion = window.matchMedia(
      '(prefers-reduced-motion: reduce)',
    ).matches

    if (!prefersReducedMotion && !saveDataEnabled) {
      this.playPause()
    }
  }

  playPause() {
    return this.player.paused ? this.player.play() : this.player.pause()
  }
}

class VideoModal {
  constructor(el) {
    this.button = el
    this.videoId = this.button.dataset.videoId
    this.videoUrl = `//www.youtube.com/embed/${this.button.dataset.videoId}?autoplay=1&rel=0`
    this.videoIframe = document.getElementById('video-iframe')
    this.modalCloseButton = document.getElementById('close-video-modal')
    this.modalOverlay = document.getElementById('modal-overlay')
    this.openHandler = this.openModal.bind(this)
    this.closeHandler = this.closeModal.bind(this)
    this.closeKeydownHandler = this.closeOnEscape.bind(this)
    this.bindEvents()
  }

  bindEvents() {
    this.button.addEventListener('click', this.openHandler)
  }

  initVideo() {
    this.videoIframe.setAttribute('src', this.videoUrl)
  }

  closeOnEscape(e) {
    if (e.keyCode === 27) {
      this.closeModal(e)
    }
  }

  openModal(e) {
    e.preventDefault()

    this.modalCloseButton.addEventListener('click', this.closeHandler)
    this.modalOverlay.addEventListener('click', this.closeHandler)
    document.addEventListener('keydown', this.closeKeydownHandler)

    const modal = document.getElementById(this.button.dataset.modalId)
    modal.classList.add('is-active')
    this.initVideo()
  }

  closeModal(e) {
    e.preventDefault()

    this.modalCloseButton.removeEventListener('click', this.closeHandler)
    this.modalOverlay.removeEventListener('click', this.closeHandler)
    document.removeEventListener('keydown', this.closeKeydownHandler)

    const modal = document.getElementById(this.button.dataset.modalId)
    modal.classList.remove('is-active')
    this.resetVideo()
  }

  resetVideo() {
    this.videoIframe.setAttribute('src', '')
  }
}

dataModules.forEach((element) => {
  element.dataset.module.split(' ').forEach(function (moduleName) {
    switch (moduleName) {
      case 'menu':
        new Menu(element)
        break

      case 'video':
        new Video(element)
        break

      case 'video-embed':
        new VideoEmbed(element)
        break

      case 'accordion-anchor':
        new AccordionAnchor(element)
        break

      case 'factoids':
        new Factoids(element)
        break

      case 'scroll-in-view':
        new ScrollInView(element)
        break

      case 'VideoControl':
        new VideoControl(element)
        break

      case 'VideoModal':
        new VideoModal(element)
        break
    }
  })
})
